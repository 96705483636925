$(document).ready(function(){

    //materialDocumentReady();
    //if($("#contactUsMap").length) {
    //    materialKitDemo.initContactUsMap();
    //}
    $('body').fitVids();

});
$(window).on("load", function (e) {
	if(window.validate_contact_form){
		validate_contact_form();
	}

})
//$(window).load(function() {

//});
$(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});